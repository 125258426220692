import React from 'react'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import Layout from '../components/layout'
import { Button, Select, Option, Input, Flex, Toast } from '@raysync/common/components'
import { Navigation, LocaleService, LOCALES } from '@raysync/common/services'
import {
  LeftContainer,
  RightContainer,
  Wrapper,
  FormItem,
  Map,
  Bubble,
  ProgressWrap,
  ProgressBar,
  Td,
  Th,
  Tr,
  Result,
  MobileResultContainer,
  MobileResultWrap,
} from '../style/calculator.atoms'
import MapImg from '../images/map.svg'
const regionZh = [
  { label: '深圳', location: { x: 630, y: 244 } },
  { label: '北京', location: { x: 634, y: 186 } },
  { label: '上海', location: { x: 649, y: 219 } },
  { label: '南京', location: { x: 642, y: 214 } },
  { label: '内蒙古', location: { x: 627, y: 180 } },
  { label: '成都', location: { x: 595, y: 230 } },
  { label: '武汉', location: { x: 628, y: 223 } },
  { label: '南昌', location: { x: 632, y: 228 } },
  { label: '新疆', location: { x: 532, y: 193 } },
  { label: '拉萨', location: { x: 542, y: 232 } },
  { label: '长沙', location: { x: 629, y: 229 } },
  { label: '杭州', location: { x: 641, y: 220 } },
  { label: '长春', location: { x: 660, y: 163 } },
  { label: '西安', location: { x: 614, y: 206 } },
  { label: '厦门', location: { x: 641, y: 236 } },
  { label: '卫星传输', location: { x: 425, y: 52 } },
]
const regionEn = [
  { label: 'Hong Kong', location: { x: 630, y: 244 } },
  { label: 'London', location: { x: 370, y: 170 } },
  { label: 'Cape Town', location: { x: 410, y: 382 } },
  { label: 'Los Angeles', location: { x: 95, y: 219 } },
  { label: 'Moscow', location: { x: 440, y: 151 } },
  { label: 'Mumbai', location: { x: 536, y: 252 } },
  { label: 'New York', location: { x: 192, y: 198 } },
  { label: 'Rio de Janeiro', location: { x: 247, y: 377 } },
  { label: 'Sydney', location: { x: 719, y: 391 } },
  { label: 'Tokyo', location: { x: 682, y: 198 } },
  { label: 'Vancouver', location: { x: 89, y: 162 } },
  { label: 'Singapore', location: { x: 610, y: 295 } },
  { label: 'Ocean vessel (Satellite)', location: { x: 425, y: 52 } },
]

const region = LocaleService.currentLocale === LOCALES.ZH ? regionZh : regionEn

const Progress = ({ percent = 0, variant = 'raysync' }) => {
  return (
    <ProgressWrap variant={variant}>
      <ProgressBar variant={variant} percent={percent}></ProgressBar>
    </ProgressWrap>
  )
}

const formatTime = second => {
  if (!second || second < 0) return ''
  if (second < 60) return intl.get('calculator.time.second', { seconds: second.toFixed(1) })
  if (second < 60 * 60)
    return intl.get('calculator.time.minute', { minutes: Math.floor(second / 60), seconds: Math.floor(second % 60) })
  if (second < 60 * 60 * 24)
    return intl.get('calculator.time.hour', {
      hours: Math.floor(second / 3600),
      minutes: Math.floor(Math.floor(second % 3600) / 60),
    })

  return intl.get('calculator.time.day', {
    days: Math.floor(second / (60 * 60 * 24)),
    hours: Math.floor(Math.floor(second % (60 * 60 * 24)) / (60 * 60)),
    minutes: Math.floor((Math.floor(second % (60 * 60 * 24)) % 3600) / 60),
  })
}

class Calculator extends React.Component {
  state = {
    initialValues: {
      origin: LocaleService.currentLocale === LOCALES.ZH ? '深圳' : 'New York',
      destination: LocaleService.currentLocale === LOCALES.ZH ? '内蒙古' : 'Hong Kong',
      fileSize: '100',
      fileSizeUnit: 'GB',
      bandWidth: '100',
      bandWidthUnit: 'Mbps',
    },
    origin: LocaleService.currentLocale === LOCALES.ZH ? '深圳' : 'New York',
    destination: LocaleService.currentLocale === LOCALES.ZH ? '内蒙古' : 'Hong Kong',
    bandWidth: '',
    raysync: {
      speed: '',
      time: '',
      utilization: '',
    },
    tcp: {
      speed: '',
      time: '',
      utilization: '',
    },
  }
  handleSubmit = values => {
    const { fileSize, fileSizeUnit, bandWidthUnit } = values

    if (values.fileSize <= 0 || values.bandWidth <= 0) {
      Toast.error(intl.get('calculator.error'))
      return
    }

    const fileSizeUnitMap = {
      MB: 1 / 1024,
      GB: 1,
      TB: 1024,
    }

    const bandWidthUnitMap = {
      Mbps: 1,
      Gbps: 1024,
    }

    const size = fileSizeUnitMap[fileSizeUnit] * fileSize
    const bandWidth = bandWidthUnitMap[bandWidthUnit] * values.bandWidth

    // 镭速传输速度 = 带宽 * (0.95 + 0.05 * 小于1的随机值)
    const raysyncSpeed = bandWidth * (0.95 + 0.05 * Math.random())
    // 镭速传输时间 = 大小（GB）*1024*8 / 镭速传输速度
    const raysyncTime = (size * 1024 * 8) / raysyncSpeed
    // 镭速带宽利用率 = 镭速传输速度 / 带宽
    const raysyncUtilization = raysyncSpeed / bandWidth

    // TCP传输速度（国内）= 带宽 * (0.3 + 0.2 * 小于1的随机值)
    // TCP传输速度（国外） = 带宽 * （0.02 + (0.01-0.1之间的随机值)）
    const tcpSpeed =
      LocaleService.currentLocale === LOCALES.ZH
        ? bandWidth * (0.3 + 0.2 * Math.random())
        : bandWidth * (0.021 + Math.random() / 10)

    // TCP传输时长（国内）= 大小（GB）*1024*8 / TCP传输速度（国内）
    const tcpTime = (size * 1024 * 8) / tcpSpeed
    // TCP带宽利用率（国内） = TCP传输速度（国内） / 带宽
    const tcpUtilization = tcpSpeed / bandWidth

    this.setState({
      raysync: {
        speed: raysyncSpeed.toFixed(2),
        time: raysyncTime,
        utilization: (100 * raysyncUtilization).toFixed(2),
      },
      tcp: {
        speed: tcpSpeed.toFixed(2),
        time: tcpTime,
        utilization: (100 * tcpUtilization).toFixed(2),
      },
      bandWidth,
    })
  }

  render() {
    const { location } = this.props
    const { initialValues, origin, destination, raysync, tcp, bandWidth } = this.state
    return (
      <Layout location={location} pageType='calculator'>
        <Wrapper>
          <LeftContainer>
            <Map>
              <img src={MapImg} alt='' />
              {/* <MapSvg preserveAspectRatio='none meet'></MapSvg> */}
              {region.map(region => (
                <Bubble
                  x={region.location.x}
                  y={region.location.y}
                  show={[origin, destination].includes(region.label)}
                ></Bubble>
              ))}
            </Map>

            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={this.handleSubmit}>
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit} autoComplete='off'>
                  <FormItem>
                    <label htmlFor=''>{intl.get('calculator.origin')}</label>
                    <Select
                      value={values.origin}
                      name='origin'
                      onChange={value => {
                        this.setState({ origin: value })
                        setFieldValue('origin', value)
                      }}
                      showArrow
                      showSearch={false}
                    >
                      {region.map(item => (
                        <Option key={item.value} value={item.label}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem>
                    <label htmlFor=''>{intl.get('calculator.destination')}</label>
                    <Select
                      value={values.destination}
                      name='destination'
                      onChange={value => {
                        this.setState({ destination: value })
                        setFieldValue('destination', value)
                      }}
                      showArrow
                      showSearch={false}
                    >
                      {region.map(item => (
                        <Option key={item.value} value={item.label}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem>
                    <label htmlFor=''>{intl.get('calculator.file-size')}</label>
                    <Flex>
                      <Input
                        name='fileSize'
                        maxLength='10'
                        value={values.fileSize}
                        onChange={event => {
                          event.persist()
                          if (/^[0-9]*$/.test(event.target.value)) {
                            setFieldValue('fileSize', event.target.value)
                          }
                        }}
                      />
                      <Select
                        name='fileSizeUnit'
                        style={{ width: '100px' }}
                        onChange={value => {
                          setFieldValue('fileSizeUnit', value)
                        }}
                        value={values.fileSizeUnit}
                      >
                        <Option value='MB' label='MB'>
                          MB
                        </Option>
                        <Option value='GB' label='GB'>
                          GB
                        </Option>
                        <Option value='TB' label='TB'>
                          TB
                        </Option>
                      </Select>
                    </Flex>
                  </FormItem>
                  <FormItem>
                    <label htmlFor=''>{intl.get('calculator.bandwidth')}</label>
                    <Flex>
                      <Input
                        name='bandWidth'
                        maxLength='10'
                        value={values.bandWidth}
                        onChange={event => {
                          event.persist()
                          if (/^[0-9]*$/.test(event.target.value)) {
                            setFieldValue('bandWidth', event.target.value)
                          }
                        }}
                      />
                      <Select
                        name='bandWidthUnit'
                        style={{ width: '100px' }}
                        onChange={value => {
                          setFieldValue('bandWidthUnit', value)
                        }}
                        value={values.bandWidthUnit}
                      >
                        <Option value='Mbps' label='Mbps'>
                          Mbps
                        </Option>
                        <Option value='Gbps' label='Gbps'>
                          Gbps
                        </Option>
                      </Select>
                    </Flex>
                  </FormItem>
                  <Button size='large' type='submit' fullWidth style={{ marginTop: '40px' }}>
                    {intl.get('calculator.start-button')}
                  </Button>
                </form>
              )}
            </Formik>
          </LeftContainer>
          <RightContainer>
            {!bandWidth && <Result>{intl.get('calculator.result')}</Result>}
            <div style={{ opacity: bandWidth ? '1' : '0', paddingTop: '70px' }}>
              <Tr bottom={39}>
                <Td></Td>
                <Th>{intl.get('calculator.transfer-speed')}</Th>
                <Th>{intl.get('calculator.transfer-time')}</Th>
                <Th>{intl.get('calculator.bandwidth-utilization')}</Th>
              </Tr>
              <Tr bottom={23} variant='raysync'>
                <Td>Raysync</Td>
                <Th>
                  <Progress percent={raysync.speed ? (raysync.speed * 100) / bandWidth : 0}></Progress>
                </Th>
                <Th>
                  <Progress percent={raysync.time ? (raysync.time * 100) / tcp.time : 0}></Progress>
                </Th>
                <Th>
                  <Progress percent={raysync.utilization ? raysync.utilization : 0}></Progress>
                </Th>
              </Tr>
              <Tr bottom={70} variant='raysync'>
                <Td></Td>
                <Th>{raysync.speed + 'Mbps'}</Th>
                <Th>{formatTime(raysync.time)}</Th>
                <Th>{raysync.utilization + '%'}</Th>
              </Tr>
              <Tr bottom={23} variant='tcp'>
                <Td>TCP</Td>
                <Th>
                  <Progress percent={tcp.speed ? (tcp.speed * 100) / bandWidth : 0} variant='tcp'></Progress>
                </Th>
                <Th>
                  <Progress percent={tcp.time ? 100 : 0} variant='tcp'></Progress>
                </Th>
                <Th>
                  <Progress percent={tcp.utilization ? tcp.utilization : 0} variant='tcp'></Progress>
                </Th>
              </Tr>
              <Tr bottom={75} variant='tcp'>
                <Td></Td>
                <Th>{tcp.speed + 'Mbps'}</Th>
                <Th>{formatTime(tcp.time)}</Th>
                <Th>{tcp.utilization + '%'}</Th>
              </Tr>
              <Tr bottom={80}>
                <Td></Td>
                <Th style={{ fontSize: '30px' }}>
                  {intl.get('calculator.faster', { num: Math.round(raysync.speed / tcp.speed) })}
                </Th>
                <Th style={{ fontSize: '30px' }}>
                  {intl.get('calculator.faster', { num: Math.round(tcp.time / raysync.time) })}
                </Th>
                <Th></Th>
              </Tr>
              <Button
                size='large'
                fullWidth
                onClick={() => {
                  Navigation.toApply({ type: 'apply' })
                }}
              >
                {intl.get('app.apply.button')}
              </Button>
            </div>
          </RightContainer>
          <MobileResultContainer>
            <Result>{intl.get('calculator.result')}</Result>
            <div style={{ opacity: bandWidth ? '1' : '0' }}>
              <MobileResultWrap>
                <div>
                  <h3>Raysync</h3>
                  <p>{intl.get('calculator.transfer-speed')}</p>
                  <Progress percent={raysync.speed ? (raysync.speed * 100) / bandWidth : 0}></Progress>
                  <span>{raysync.speed + 'Mbps'}</span>
                  <p>{intl.get('calculator.transfer-time')}</p>
                  <Progress percent={raysync.time ? (raysync.time * 100) / tcp.time : 0}></Progress>
                  <span>{formatTime(raysync.time)}</span>
                  <p>{intl.get('calculator.bandwidth-utilization')}</p>
                  <Progress percent={raysync.utilization ? raysync.utilization : 0}></Progress>
                  <span>{raysync.utilization + '%'}</span>
                  <b> {intl.get('calculator.faster', { num: Math.round(raysync.speed / tcp.speed) })}</b>
                </div>
                <div>
                  <h3>TCP</h3>
                  <p>{intl.get('calculator.transfer-speed')}</p>
                  <Progress percent={tcp.speed ? (tcp.speed * 100) / bandWidth : 0}></Progress>
                  <span>{tcp.speed + 'Mbps'}</span>
                  <p>{intl.get('calculator.transfer-time')}</p>
                  <Progress percent={tcp.time ? (tcp.time * 100) / tcp.time : 0}></Progress>
                  <span>{formatTime(tcp.time)}</span>
                  <p>{intl.get('calculator.bandwidth-utilization')}</p>
                  <Progress percent={tcp.utilization ? tcp.utilization : 0}></Progress>
                  <span> {tcp.utilization + '%'}</span>

                  <b> {intl.get('calculator.faster', { num: Math.round(tcp.time / raysync.time) })}</b>
                </div>
              </MobileResultWrap>
              <Button
                size='large'
                fullWidth
                onClick={() => {
                  Navigation.toApply({ type: 'apply' })
                }}
              >
                {intl.get('app.apply.button')}
              </Button>
            </div>
          </MobileResultContainer>
        </Wrapper>
      </Layout>
    )
  }
}

export default Calculator
