import styled, { css, keyframes } from 'styled-components'
import { H2 } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
export const Wrapper = styled.div`
  display: flex;
  min-height: 960px;
  ${Media.phone`
    padding-top: .3rem;
    /* padding: .3rem; */
    flex-wrap:wrap;
    min-height: unset;
  `}
`

export const LeftContainer = styled.div`
  background: #232734;
  padding: 30px 100px 0;
  width: 50%;
  svg {
    width: 780px;
    height: 460px;
    margin-bottom: 20px;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  ${Media.phone`
    padding: .5rem 0.3rem .9rem;
    width: 100%;
    img {
      max-width: 6.9rem;
    }
    svg {
      width: 7rem;
      max-width: 7rem;
      height: 4.12rem;
      path {
        max-width: 100%;
      }
    }
  `}
`

export const RightContainer = styled.div`
  width: 50%;
  background: #16181f;
  padding: 30px 100px 0;
  ${Media.phone`
    display: none;
  `}
`

export const FormItem = styled.div`
  width: 360px;
  margin-top: 18px;
  label {
    font-size: 16px;
    line-height: 54px;
    color: #8099b9;
  }
  input {
    margin: 0;
    color: #fff !important;
  }
  .rc-select-selection-item {
    color: #fff !important;
  }
  ${Media.phone`
    margin-top: .28rem;
    width: 100%;
    label {
      display: block;
      line-height: 1.5;
      margin-bottom: .34rem;
    }
    input {
      height: .8rem;
      line-height: .8rem;
    }
    .rc-select-selector  {
      height: .8rem;
      line-height: .8rem;
    }
  `}
`

export const Map = styled.div`
  position: relative;
  width: 780px;
  ${Media.phone`
    width: 100%;
  `}
`

const scaleIn = keyframes`
  0% {
      transform: scale(1);
      opacity:.9
  }
  100% { transform: scale(4);
    opacity: 0;
  }
`
const scaleOut = keyframes`
  0%{
    transform: scale(1);
    opacity:.9;
  }
  100% {
    transform: scale(6);
    opacity:0;
  }
`
const MAP_RATIO = 6.9 / 7.8
export const Bubble = styled.div`
  position: absolute;
  left: ${p => p.x + 'px'};
  top: ${p => p.y + 'px'};
  display: ${p => (p.show ? 'block' : 'none')};
  /* visibility: ${p => (p.show ? 'visible' : 'hidden')}; */

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    /* background-color: rgba(241, 34, 30, 0.9); */
    background-color: rgba(221, 80, 27, 0.9);
  }
  ::before {
    animation: ${scaleIn} 2.5s infinite;
  }
  ::after {
    animation: ${scaleOut} 2.5s infinite;
  }
  ${Media.phone`
    left: ${p => (p.x / 100) * MAP_RATIO + 'rem'};
    top: ${p => (p.y / 100) * MAP_RATIO + 'rem'};
  `}
`

export const ProgressWrap = styled.div`
  ${({ theme: { color }, variant }) => css`
    display: flex;
    border: 1px solid ${variant === 'raysync' ? color.secondary : '#8199B9'};
    overflow: hidden;
    width: 200px;
    height: 24px;
    border-radius: 12px;
    ${Media.phone`
      width: 100%;
      height: .24rem;
      border-radius: .12rem;
    `}
  `}
`

export const ProgressBar = styled.div`
  ${({ theme: { color }, percent, variant }) => css`
    white-space: nowrap;
    background-color: ${variant === 'raysync' ? color.secondary : '#8199B9'};
    transition: width 1.2s ease;
    width: ${percent + '%'};
  `}
`

export const Td = styled.p`
  ${({ theme: { color }, variant }) => css`
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-align: right;
    width: 65px;
  `}
`

export const Th = styled.p`
  /* color: white; */
  font-size: 16px;
  width: 200px;
`

export const Tr = styled.div`
  ${({ theme: { color }, variant }) => {
    return css`
      display: flex;
      justify-content: space-between;
      padding-bottom: ${p => p.bottom + 'px'};
      padding-top: ${p => p.top + 'px'};
      color: ${variant === 'raysync' ? color.secondary : variant === 'tcp' ? '#8199B9' : 'white'};
    `
  }}
`

export const Result = styled(H2)`
  text-align: center;
  color: white;
`

export const MobileResultContainer = styled.div`
  width: 100%;
  padding: 0.7rem 0.5rem;
  background: #16181f;
  h3 {
    font-size: 0.3rem;
    color: ${p => p.theme.color.secondary};
    margin-bottom: 0.36rem;
    margin-top: 0.66rem;
  }
  p {
    font-size: 0.24rem;
    color: #fff;
    margin-bottom: 0.16rem;
  }
  span {
    display: block;
    font-size: 0.24rem;
    margin-top: 0.22rem;
    margin-bottom: 0.66rem;
    color: ${p => p.theme.color.secondary};
  }
  b {
    display: block;
    color: #fff;
    font-size: 0.36rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  ${Media.desktop`
    display:none;
  `}
`
export const MobileResultWrap = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 3rem;
    :last-child {
      h3,
      span {
        color: #8099b9;
      }
      ${ProgressWrap} {
        border-color: #8099b9;
      }
      ${ProgressBar} {
        background-color: #8099b9;
      }
    }
  }
`
